import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Products = () => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2>产品展示</h2>
                    <p>这里是我们的产品展示页面。</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Products;
